.allproducts{
    background: #FFFBF2;
}

.cutlery{
    margin-top: 3rem;
}
.containers{
    margin-top: 3rem;
}

.cups{
    margin-top: 3rem;
}