.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; 
  }
  
  .navbar-nav li a {
    letter-spacing: 1px;
    font-weight: 500;
  }

  .navbarLogo{
    width: 200px;
    height: auto;
  }