.footer{
    background-image: url('../../Images/footerbg.avif');
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.footerOverlay{
    background-color: rgba(0, 0, 0, 0.74);
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
}
.footerOverlay h4{
    padding-bottom: 1rem;
}
.footer-logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.social-icons a {
    color: white;
    font-size: 1.2rem;
}

.gallery {
    display: flex;
    flex-wrap: wrap;
    columns: 3;
}

.gallery-img {
    width: 100%;
    /* margin: 5px; */
    border-radius: 5px;
}

.footer .input-group input {
    border: none;
    border-radius: 0;
}

.footer .input-group .btn {
    border-radius: 0;
}

.footer p, .footer h5 {
    margin: 0;
}
