@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Poppins", serif;
  letter-spacing: 1px;
}


:root {
  --color-greeen: #169667;
}

.greenheading {
  color: var(--color-greeen);
}
.page-content {
  margin-top: 4rem;
}

.counterUp h2 {
  text-align: center;
  color: white;
  margin-bottom: 0;
}

.breadcrumb {
  background-image: url("./Images/breadcrump.jpeg");
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
}

.breadOverlay {
  background-color: rgba(0, 0, 0, 0.447);
  height: 100%;
  width: 100%;
  padding: 3rem 7rem;
  color: white;
}

.breadcrumb p a {
  margin-bottom: 0;
  color: white;
  text-decoration: none;
}

.breadcrumb p {
  margin-bottom: 0;
}

.allbackgrounds {
  background-color: #fffbf2;
  padding: 2rem 2rem;
}

.counterUp{
  padding: 5rem 5rem;
}